import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Picture from "@components/ui/picture";

export const CollectionsContainer = styled.div`
	${({ theme: { colors, unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: ${unit * 8}px;
		color: ${colors.black};
		max-width: ${unit * 200}px;
		width: 100%;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			gap: ${unit * 5}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			gap: ${unit * 3}px;
		}
	`}
`;

export const Title = styled.h2`
	${({ theme: { breakpoints } }) => css`
		font-size: 48px;
		line-height: 57.7px;
		text-align: center;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 40px;
			line-height: 48.08px;
		}
	`}
`;

export const CollectionsList: typeof Swiper = styled(Swiper)`
	${({ theme: { colors, unit, breakpoints } }) => css`
		width: 100%;

		.swiper {
			overflow-y: visible !important;
		}

		.swiper-wrapper {
			display: flex;
			position: inherit !important;
		}

		.swiper-button-disabled {
			display: none;
		}

		.swiper-button-next {
			right: var(--swiper-navigation-sides-offset, 0);
		}

		.swiper-button-prev {
			left: var(--swiper-navigation-sides-offset, 0);
		}

		.swiper-button-prev,
		.swiper-button-next {
			width: ${unit * 10}px;
			height: ${unit * 10}px;
			background-color: ${colors.white};

			&::after {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83333 -4.00688e-07L9.83333 22M9.83333 22L19 12.8333M9.83333 22L0.666666 12.8333' stroke='%23524E55' stroke-width='1.2' /%3E%3C/svg%3E");
				background-position: center center;
				background-repeat: no-repeat;
				width: ${unit * 3}px;
				height: ${unit * 3}px;
				transition: 0.3s all ease-in-out;
			}

			&:hover {
				&::after {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83333 -4.00688e-07L9.83333 22M9.83333 22L19 12.8333M9.83333 22L0.666666 12.8333' stroke='%23c7b299' stroke-width='1.2' /%3E%3C/svg%3E%0A");
				}
			}
		}

		.swiper-button-prev {
			transform: rotate(90deg);
		}

		.swiper-button-next {
			transform: rotate(270deg);
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
	`}
`;

export const CollectionsItem = styled(SwiperSlide)<{ $background: any }>`
	${({ theme: { unit, breakpoints }, $background }) => css`
		background-image: url(${$background});
		background-origin: border-box;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: ${unit * 80}px;
		width: ${unit * 125}px;
		position: relative;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			height: ${unit * 46.75}px;
			width: ${unit * 73}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			height: fit-content;
			display: flex;
			flex-direction: column;
			background-image: none;
			gap: ${unit * 3}px;
			width: fit-content;
		}
	`}
`;

export const Image = styled(Picture)`
	${({ theme: { breakpoints } }) => css`
		display: none;
		width: 100%;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			display: block;
		}
	`}
`;

export const TextWrapper = styled(Link)`
	${({ theme: { unit, breakpoints } }) => css`
		position: absolute;
		bottom: 0;
		right: ${unit * 12.5}px;
		display: flex;
		flex-direction: column;
		padding-bottom: ${unit * 3}px;
		gap: ${unit}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			right: 0;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			position: static;
		}
	`}
`;

export const ItemTitle = styled.h3`
	${({ theme: { breakpoints } }) => css`
		font-size: 40px;
		line-height: 48.08px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 24px;
			line-height: 28.85px;
		}
	`}
`;

export const ItemDesc = styled.p`
	${({ theme: { unit, colors } }) => css`
		font-size: 21px;
		line-height: 25.24px;
		border-bottom: 1px solid ${colors.beige};
		padding-bottom: ${unit * 0.5}px;
		margin: 0;
		width: fit-content;
		transition: 0.3s all ease-in-out;
		color: ${colors.warmGray};
	`}
`;

export const DescWrapper = styled.div`
	${({ theme: { unit, colors } }) => css`
		display: flex;
		align-items: center;

		svg {
			width: ${unit * 2}px;
			height: ${unit * 2}px;
			color: ${colors.warmGray};
			transform: rotate(270deg);
			opacity: 0;
			transition: 0.5s;
		}

		&:hover {
			svg {
				opacity: 1;
				transform: translate(16px) rotate(270deg);
			}
		}
	`}
`;

export const Button = styled(Link)`
	${({ theme: { colors, unit } }) => css`
		font-family: 'Inter';
		outline: none;
		border: 1px solid ${colors.warmGray};
		color: ${colors.warmGray};
		background-color: transparent;
		padding: ${unit * 2.375}px ${unit * 3}px;
		font-size: 15px;
		font-weight: 300;
		line-height: 18.15px;
		height: ${unit * 7}px;
		width: fit-content;
		transition: 0.3s all ease-in-out;

		&:hover {
			background-color: ${colors.beige};
			border-color: ${colors.beige};
			color: ${colors.white};
		}
	`}
`;

CollectionsItem.displayName = 'SwiperSlide'
