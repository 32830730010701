import React from 'react';
import Main from '@views/main';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { initializeStore } from '@core/stores/root-store';

const Index = () => {
	return <Main />;
};

export async function getServerSideProps({ locale }: { locale: string }) {
	const store = initializeStore();

	await store.core.fetchCollections();
	await store.core.fetchCategories();

	return {
		props: {
			collections: store.core.collections,
			categories: store.core.categories,
			initialStore: JSON.parse(JSON.stringify(store)),
			...(await serverSideTranslations(locale, ['translation'])),
		},
	};
}

export default Index;
