import styled, { css } from 'styled-components';
import background from '@resources/images/main/banner.jpg';

export const BannerContainer = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		background-image: url(${background.src});
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-origin: border-box;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: calc((100dvw - 2 * ${unit * 2.5}px) * 0.54);
		position: relative;
		padding: ${unit * 2.5}px;

		@media (max-width: ${breakpoints.tableWidth}) {
			height: ${unit * 83}px;
		}
	`}
`;

export const Title = styled.h1`
	${({ theme: { unit, breakpoints } }) => css`
		font-size: 64px;
		line-height: 76.93px;
		text-transform: uppercase;
		text-align: center;
		max-width: ${unit * 85}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 48px;
			line-height: 57.7px;
		}
	`}
`;

export const ExploreWrapper = styled.div`
	${({ theme: { unit, colors } }) => css`
		position: absolute;
		bottom: ${unit * 5}px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${unit * 4}px;
		width: fit-content;
		cursor: pointer;
		color: white;

		svg {
			width: ${unit * 3}px;
			height: ${unit * 3}px;
			transition: 0.3s all ease-in-out;
		}

		&:hover {
			svg {
				color: ${colors.beige};
			}
			p {
				transform: translateY(-1rem);
			}
		}
	`}
`;

export const Text = styled.p`
	font-family: 'Inter';
	font-weight: 300;
	font-size: 15px;
	line-height: 18.15px;
	transition: 0.3s all ease-in-out;
`;

export const DistributorWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
`;

export const BannerWrapper = styled.div`
    display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
`;