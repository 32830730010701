import React from 'react';
import * as S from './styled';
import SEO from "@components/entities/seo";
import Banner from "@views/main/components/banner";
import {Collections} from "@views/main/components/collections";
import Products from "@views/main/components/products";
import {Discover} from "@views/main/components/discover";
import {Innovations} from "@views/main/components/innovations";

export const Main = () => {
    return (
        <>
            <SEO config={{
                title: "Artiviana 1956 – Das Schlagende Herz des Hauses",
                description: "Entdecken Sie Produkte aus den Bereichen Bad, Dusche und Sanitär."
            }}/>
            <S.Wrapper>
                <Banner/>
                <Products/>
                <Collections/>
                <Discover/>
                <Innovations/>
            </S.Wrapper>
        </>
    );
};

export default Main;
