import React from 'react';
import * as S from './styled';
import { CustomIcon } from '@components/ui';
import { useTranslation } from 'next-i18next';
import DistributorLabel from "@components/ui/distributor-label";
import {useMedia} from "@core/hooks/use-media";
import {useStore} from "@core/hooks/use-store";
import {observer} from "mobx-react-lite";

const Banner = () => {
	const { t } = useTranslation();
	const scrollToAnchor = (anchor: string) => {
		const anchorElement = document.getElementById(anchor);
		if (anchorElement) {
			anchorElement.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const {isClient} = useStore("core")

	const { isMobile } = useMedia();

	return (
		<S.BannerWrapper>
			<S.BannerContainer>
				<S.Title>{t('main.title')}</S.Title>
				<S.ExploreWrapper onClick={() => scrollToAnchor('products')}>
					<S.Text>{t('main.button')}</S.Text>
					<CustomIcon fileName="icon-arrow" />
				</S.ExploreWrapper>
				{!isMobile && isClient && (
					<S.DistributorWrapper>
						<DistributorLabel footer={false}/>
					</S.DistributorWrapper>
				)}
			</S.BannerContainer>
			{isMobile && isClient && <DistributorLabel footer={false}/>}
		</S.BannerWrapper>

	);
};

export default observer(Banner);
